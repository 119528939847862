<template>
  <div class="container">
    <div class="header mb-3 mp-print-0">
      <div class="header-body mp-print-0">
        <h1 class="header-title noPrint">HCAI Reports</h1>
        <div class="row">
          <div class="col-12">
            <Navbar />
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Navbar from "./Navbar";
export default {
  name: "HCAIReports",
  components: {
    Navbar,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>
