<template>
  <div>
    <ul class="nav nav-tabs header-tabs noPrint profile-nav-container">
      <li
        v-for="link in displayedNav"
        :key="link.to || link.action"
        :class="
          link.dropdown
            ? `nav-item dropdown ${link.class || ''}`
            : `nav-item ${link.class || ''}`
        "
      >
        <!-- Dropdown -->
        <a
          class="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          v-if="link.dropdown"
        >
          <icon :type="link.icon" class="mr-1" />
          {{ link.label }}
        </a>
        <div class="dropdown-menu" v-if="link.dropdown">
          <router-link
            v-for="drop in link.dropdown"
            :key="drop.label"
            :to="getLink(drop.to)"
            class="dropdown-item"
            :class="name == drop.to ? `active` : ``"
          >
            <icon :type="drop.icon" class="mr-1" />
            {{ drop.label }}
          </router-link>
        </div>

        <!-- Single -->
        <router-link
          v-if="!link.dropdown && !link.action"
          :to="getLink(link.to)"
          class="nav-link"
          :class="name == link.to ? `active` : ``"
        >
          <icon :type="link.icon" class="mr-1" />
          {{ link.label }}
        </router-link>
        <a
          v-if="!link.dropdown && link.action"
          href="/"
          @click="openCalendarModal"
          class="nav-link"
        >
          <icon :type="link.icon" class="mr-1" />
          {{ link.label }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Navbar",
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    name: function () {
      return this.$route.name;
    },
    displayedNav: function () {
      if (this.user && this.user.isProvider) {
        return this.nav.filter(
          (item) =>
            item.label != "Providers" &&
            !item.hideForProvider &&
            !item.onlyPatient
        );
      } else if (this.user && this.user.isPatient) {
        return this.nav.filter((item) => !item.hideForPatient);
      } else {
        return this.nav.filter((item) => !item.onlyPatient);
      }
    },
  },
  data() {
    return {
      modalOpened: false,
      nav: [
        {
          label: "HCAI Report",
          to: "HCAIBillingReport",
          icon: "h-square",
        },
        // {
        //   label: "OCF21B Invoices",
        //   to: "ocf21b",
        //   icon: "file-invoice-dollar",
        // },
        // {
        //   label: "OCF21B Sent Invoices",
        //   to: "ocf21b-sent-invoices",
        //   icon: "receipt",
        // },
      ],
    };
  },
  methods: {
    getLink(to) {
      return {
        name: to,
      };
    },
  },
};
</script>
